<template>
  <div>
    <app-tabs-filter
      @getCurrentRegion="getCurrentRegion"
      @getAllRegion="getAllRegion"
      :tabList="tabList"
      :currentRegion="currentRegion"
    ></app-tabs-filter>
    <app-data-grid :items="countriesItems"></app-data-grid>
  </div>
</template>

<script>
import appDataGrid from "../app-dataGrid.vue";
import AppTabsFilter from "../common/app-tabs_filter.vue";
export default {
  components: { appDataGrid, AppTabsFilter },
  props: {
    propsData: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      tabList: null,
      items: null,
      currentRegion: 'all',
    };
  },
  computed: {
    countriesItems() {
      const temp = [];
      if ( this.currentRegion !== 'all' ) {
        return this.items[this.currentRegion];
      } else if (this.currentRegion === "all") {
        for (let el in this.items) {
          this.items[el].forEach((item) => {
            temp.push(item);
          });
        }
        return temp;
      } else {
        const region = Object.keys(this.items)[0];
        return this.items[region];
      }
    },
  },
  methods: {
    getCurrentRegion(region) {
      this.currentRegion = region;
    },
    getAllRegion() {
      this.currentRegion = "all";
    },
  },
  mounted() {
    this.tabList = this.propsData.tabs;
    this.items = this.propsData.items;
  },
};
</script>

<style lang="sass" scoped>
</style>
