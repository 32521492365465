<template>
  <div class="chooseCountry" v-if="!isUiLocked">
    <div class="container textContainer">
        <the-main-text
            :title="countryInfo.title"
            :descr="countryInfo.description"
        ></the-main-text>
      <!-- <h1 class="sectionTitle bef">Популярні туристичні направлення</h1>
      <p class="descr">
        Підбирай потрібну країну для подорожі в будь який із материків. Подорожі
        з Dream Travel - це незабуваємі моменти твого життя!
      </p> -->
      <!-- <the-main-text
        :title="tourInfo.name"
        :descr="tourInfo.description"
      ></the-main-text> -->
    </div>
    <component
        v-for="(item, idx) in widgetList"
        :key="idx+'w'"
        :is="item.component"
        :propsData="item.content"
      >
      </component>
      <component
        v-for="(item, idx) in constructorList"
        :key="idx"
        :is="item.component"
        :propsData="item.content"
      >
      </component>
    <app-news></app-news>
  </div>
</template>

<script>
import AppNews from "../components/app-news.vue";
import AppListItem from "../components/common/app-listItem.vue";
import appDataGrid from "../components/app-dataGrid.vue";
import { mapActions, mapGetters } from "vuex";
import AppTabsFilter from "../components/common/app-tabs_filter.vue";
import AppCountries from '../components/katalog/app-countries.vue';
import TheMainText from '../components/common/the-main-text.vue';
export default {
  components: {
    "image-and-text": AppListItem,
    AppNews,
    appDataGrid,
    AppTabsFilter,
    "countries": AppCountries,
    TheMainText,
  },
  data() {
   return {
      widgetList: null,
      countryInfo: null,
    };
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
    fetchOption() {
        let temp = {
            slug: 'katalog',
            lang: this.currentLang,
        }
        if ( this.$route.name == 'CatalogChild') {
            temp.month = this.$route.params.month
            temp.type = this.$route.params.slug
        }
        return temp
    }
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"]),
  },
  async created() {
    this.lockUi();
    const res = await this.axios.post("/api/page/get-by-slug", this.fetchOption);
    this.countryInfo = res.data.data.translate
    this.widgetList = res.data.data.widgets;
    this.constructorList = res.data.data.constructor;
    document.title = res.data.data.translate.meta_title || res.data.data.translate.title || "Dream Travel"
    this.unlockUi();
  },
};
</script>

<style lang="sass" scoped>
.descr
    font-weight: 300
    font-size: 17px
    line-height: 150%
    text-align: center
    color: #FFFFFF
    max-width: 586px
    margin: 0 auto
    margin-bottom: 70px
.textContainer
    text-align: center
    padding-top: 70px
</style>
